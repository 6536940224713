import { sendPut, sendDelete, sendPost, sendGet } from './axios';

export const getListLanguages = () => {
  return sendPost('/cms/language/list');
};

export const getListLanguageKeys = (payload: any) => sendPost('/cms/language/list-language-key', payload);

export const getListEnvironments = () => sendPost('/cms/language/list-environments');

export const updateLanguageKey = (payload: any) => sendPut(`/cms/language/update-language-key`, payload);

export const getFileLanguage = (payload: any) => sendPost('/cms/language/get-file-language', payload);

export const addLanguageKey = (payload: any) => sendPut('/cms/language/add-language-key', payload);

export const uploadFile = (payload: any) => sendPost('/cms/language/upload-file-language', payload);
