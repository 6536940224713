import { sendGet, sendPut, sendPost } from './axios';

// eslint-disable-next-line import/prefer-default-export
export const getListRoles = (params: any) => sendGet('/cms/permissions/role', params);

export const addRole = (payload: any) => sendPost('/cms/permissions/role', payload);

export const updateRole = (roleId: number, payload: { roleName: string }) =>
  sendPut(`/cms/permissions/role/${roleId}`, payload);

export const hiddenRole = (roleId: number, payload: { isVisible: number }) =>
  sendPut(`/cms/permissions/hidden-role/${roleId}`, payload);

export const getListPermissionByRole = (roleId: number) => sendGet(`/cms/permissions/rolePermission/${roleId}`);

export const updatePermissionByRole = (
  roleId: number,
  payload: { permissions: Array<number>; changeUserPermission: number }
) => sendPut(`/cms/permissions/rolePermission/${roleId}`, payload);

export const getListPermission = () => sendGet('/permission');
