import React from 'react';
import {
  ExceptionOutlined,
  PhoneOutlined,
  UserOutlined,
  DesktopOutlined,
  NotificationOutlined,
  ShoppingCartOutlined,
  DollarOutlined,
} from '@ant-design/icons';
import DashBoard from 'assets/images/dashboard.svg';

import Payment from 'assets/images/payment.svg';
import Employee from 'assets/images/employee.svg';
import Shop from 'assets/images/shopping.svg';
import Setting from 'assets/images/setting.svg';

interface IObject {
  [key: string]: any;
}

const IconMaps: IObject = {
  DashBoard: <img src={DashBoard} className="c-sidebar-nav-icon" alt="" />,
  Shop: <ShoppingCartOutlined className="c-sidebar-nav-icon" alt="" />,
  Member: <UserOutlined className="c-sidebar-nav-icon" alt="" />,
  Payment: <img src={Payment} className="c-sidebar-nav-icon" alt="" />,
  Revenue: <DollarOutlined className="c-sidebar-nav-icon" alt="" />,
  Employee: <img src={Employee} className="c-sidebar-nav-icon" alt="" />,
  Setting: <img src={Setting} className="c-sidebar-nav-icon" alt="" />,
  Report: <ExceptionOutlined className="c-sidebar-nav-icon" />,
  System: <DesktopOutlined className="c-sidebar-nav-icon" />,
  Contact: <PhoneOutlined className="c-sidebar-nav-icon" />,
  Notification: <NotificationOutlined className="c-sidebar-nav-icon" />,
};

export default IconMaps;
