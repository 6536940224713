import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export default function HelmetHeader() {
  const { t } = useTranslation();
  const getHelmetHeader = (pathname: string) => {
    switch (pathname) {
      case '/member':
        return t('nav.member');
      case '/restaurant':
        return t('nav.restaurant');
      case '/revenue':
        return t('nav.revenue');
      case '/notification':
        return t('nav.notification');
      case '/staffs':
        return t('nav.staff');
      case '/languages':
        return t('nav.language');
      case '/roles':
        return t('nav.role');
      case '/config-admin':
        return t('nav.config');
      case '/resources/template-mail':
        return t('nav.template_mail');
      case '/resources/guide-and-QA':
        return t('nav.guide_and_QA');
      case '/resources/member-benefit':
        return t('nav.member_benefit');

      case '/resources/my-page':
        return t('nav.my_page');
      case '/resources/ken':
        return t('nav.ken');
      case '/resources/city':
        return t('nav.city');
      case '/resources/hashtag':
        return t('nav.hashtag');
      case '/resources/category':
        return t('nav.category');
      case '/resources/restaurant-type':
        return t('nav.restaurant_type');

      case '/account/profile':
        return t('nav.profile');
      case '/account/change-password':
        return t('nav.change_password');
      default:
        break;
    }
  };
  return (
    <Helmet>
      <title>{getHelmetHeader(window.location.pathname)}</title>
    </Helmet>
  );
}
