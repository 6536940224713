import React, { useEffect, useState } from 'react';
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from '@coreui/react';
import IconMaps from 'components/IconMaps';
import { useTranslation } from 'react-i18next';
import { PERMISSIONS } from 'common/enum';
import { ReactComponent as LogoApp } from 'assets/images/logo_app.svg';
import { ReactComponent as TextApp } from 'assets/images/text_app.svg';

import { useHistory } from 'react-router-dom';
import routes from 'routes';

export default function SideNav({ sidebarShow, onShowChange }: any) {
  const { t } = useTranslation();
  const [listPermissions, setListPermissions] = useState<any>([]);
  const history = useHistory();

  useEffect(() => {
    const listPermissionUser: any = localStorage.getItem('permissions')?.split(',');
    setListPermissions(listPermissionUser.map((x: any) => Number(x)));
  }, []);
  const routes = JSON.parse(
    JSON.stringify([
      {
        _tag: 'CSidebarNavItem',
        name: t('nav.member'),
        to: '/member',
        icon: 'Member',
        visible: !!listPermissions?.includes(PERMISSIONS.MEMBER_MANAGEMENT),
      },
      {
        _tag: 'CSidebarNavItem',
        name: t('nav.restaurant'),
        to: '/restaurant',
        icon: 'Shop',
        visible: !!listPermissions?.includes(PERMISSIONS.RESTAURANT_MANAGEMENT),
      },
      {
        _tag: 'CSidebarNavItem',
        name: t('nav.payment'),
        to: '/payment',
        icon: 'Payment',
        visible: !!listPermissions?.includes(PERMISSIONS.RESOURCE_MANAGEMENT),
      },
      {
        _tag: 'CSidebarNavItem',
        name: t('nav.revenue'),
        to: '/revenue',
        icon: 'Revenue',
        visible: !!listPermissions?.includes(PERMISSIONS.RESOURCE_MANAGEMENT),
      },
      {
        _tag: 'CSidebarNavDropdown',
        name: t('nav.notification'),
        icon: 'Notification',
        visible: !!listPermissions?.includes(PERMISSIONS.NOTIFICATION_MANAGEMENT),

        _children: [
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.template_notification'),
            to: '/template-notifications',
            icon: 'UserOutlined',
            visible: !!listPermissions?.includes(PERMISSIONS.USER_MANAGEMENT),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.notification_manually'),
            to: '/notification',
            icon: 'FileSyncOutlined',
            visible: !!listPermissions?.includes(PERMISSIONS.LANGUAGE_MANAGEMENT),
          },

          // {
          //   _tag: 'CSidebarNavItem',
          //   name: t('nav.role'),
          //   to: '/roles',
          //   visible: !!listPermissions?.includes(PERMISSIONS.ROLE_MANAGEMENT),
          // },
        ],
      },

      {
        _tag: 'CSidebarNavDropdown',
        name: t('nav.system'),
        icon: 'System',
        visible:
          !!listPermissions?.includes(PERMISSIONS.LANGUAGE_MANAGEMENT) ||
          !!listPermissions?.includes(PERMISSIONS.ROLE_MANAGEMENT) ||
          !!listPermissions?.includes(PERMISSIONS.USER_MANAGEMENT),
        _children: [
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.staff'),
            to: '/staffs',
            icon: 'UserOutlined',
            visible: !!listPermissions?.includes(PERMISSIONS.USER_MANAGEMENT),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.language'),
            to: '/languages',
            icon: 'FileSyncOutlined',
            visible: !!listPermissions?.includes(PERMISSIONS.LANGUAGE_MANAGEMENT),
          },

          // {
          //   _tag: 'CSidebarNavItem',
          //   name: t('nav.role'),
          //   to: '/roles',
          //   visible: !!listPermissions?.includes(PERMISSIONS.ROLE_MANAGEMENT),
          // },
        ],
      },
      {
        _tag: 'CSidebarNavDropdown',
        name: t('nav.setting'),
        icon: 'Setting',
        visible: !!listPermissions?.includes(PERMISSIONS.RESOURCE_MANAGEMENT),
        _children: [
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.config_register'),
            to: '/config-register',
            visible: true,
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.top_page'),
            to: '/resources/top-page',
            icon: 'FileSyncOutlined',
            visible: true,
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.config_admin'),
            to: '/config-admin',
            visible: true,
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.banner_top'),
            to: '/resources/banner-top',
            visible: true,
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.banner_attractive'),
            to: '/resources/banner-attractive',
            visible: true,
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.profession'),
            to: '/resources/profession',
            visible: true,
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.age'),
            to: '/resources/age',
            visible: true,
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.template_mail'),
            to: '/resources/template-mail',
            icon: 'FileSyncOutlined',
            visible: true,
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.guide_and_QA'),
            to: '/resources/guide-and-QA',
            icon: 'FileSyncOutlined',
            visible: true,
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.member_benefit'),
            to: '/resources/member-benefit',
            icon: 'FileSyncOutlined',
            visible: true,
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.my_page'),
            to: '/resources/my-page',
            icon: 'FileSyncOutlined',
            visible: true,
          },

          {
            _tag: 'CSidebarNavItem',
            name: t('nav.ken'),
            to: '/resources/ken',
            icon: 'FileSyncOutlined',
            visible: true,
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.city'),
            to: '/resources/city',
            icon: 'FileSyncOutlined',
            visible: true,
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.hashtag'),
            to: '/resources/hashtag',
            icon: 'FileSyncOutlined',
            visible: true,
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.category'),
            to: '/resources/category',
            icon: 'FileSyncOutlined',
            visible: true,
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.restaurant_type'),
            to: '/resources/restaurant-type',
            icon: 'FileSyncOutlined',
            visible: true,
          },
        ],
      },
    ]),
    (key, value: any) => {
      if (key === 'icon') {
        return IconMaps[value];
      }
      return value;
    }
  );
  useEffect(() => {
    if (history.location.pathname === '/') {
      const firstItem = routes.find((item: any) => {
        return item.visible;
      });
      if (firstItem?.hasOwnProperty('_children')) {
        const link = firstItem?._children.find((subItem: any) => subItem.visible);
        history.push(link?.to);
      } else {
        history.push(firstItem?.to);
      }
    }
  }, [history.location.pathname, listPermissions]);

  const visibleRoutes = routes.filter((item: any) => {
    return !!item.visible;
  });

  const visibleRoutesChildren = visibleRoutes.map((item: any) => {
    if (item.hasOwnProperty('_children')) {
      const arrSubItem = item._children.filter((subItem: any) => !!subItem.visible);
      return { ...item, _children: arrSubItem };
    }
    return item;
  });

  return (
    <CSidebar
      minimize={sidebarShow}
      // onShowChange={(val: any) => onShowChange(val)}
      onMinimizeChange={(value: any) => console.log(value)}
      size={'lg'}
    >
      <CSidebarBrand to="/">
        <div className="logo-app-auth">
          <LogoApp />
          <TextApp className="logo-text-auth" />
          {/* <span className="logoText">Agora</span> */}
          {/* <img src={logo} alt="" className="img-fluid" /> */}
        </div>
      </CSidebarBrand>
      <CSidebarNav className="hNav">
        <CCreateElement
          items={visibleRoutesChildren}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle,
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
}
