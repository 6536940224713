import { GENDER, HASHTAG_TYPE, STATUS_PAYMENT } from './../common/enum';
import configs from 'config';
import CustomNotification from 'components/CustomNotification';

import i18n from 'i18next';

export const handleErrorMessage = (error: any) => {
  CustomNotification({
    type: 'error',
    message: i18n.t('common.error'),
    description: getErrorMessage(error),
  });
  if (configs.APP_ENV !== 'prod') {
    // tslint:disable-next-line: no-console
    console.log(error);
  }
};

export const getErrorMessage = (error: any) => {
  const errorMessage = error?.response?.data?.errorMessage;
  return errorMessage ? errorMessage : 'Something went wrong!';
};

export const assignDomain = (url: string) => {
  if (url?.startsWith('http')) return url;
  return `${configs.AWS_DOMAIN}/${url}`;
};

export const getBase64 = (img: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result as string));
    reader.readAsDataURL(img);
  });

export const getDataFile = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result as string));
    reader.readAsText(file);
  });

export const getGenderText = (gender: GENDER.MALE | GENDER.FEMALE) => {
  if (gender === GENDER.MALE) {
    return i18n.t('option.male');
  }
  if (gender === GENDER.FEMALE) {
    return i18n.t('option.female');
  }
};

export const formatPriceToJpString = (price: number) => price?.toLocaleString('ja-JP');

export const getHashtagByType = (type: number) => {
  if (type === HASHTAG_TYPE.COMMON) {
    return i18n.t('option.hashtag_common');
  }
  if (type === HASHTAG_TYPE.BUDGET) {
    return i18n.t('option.hashtag_budget');
  }
  if (type === HASHTAG_TYPE.SMOKING) {
    return i18n.t('option.hashtag_smoking');
  }
  if (type === HASHTAG_TYPE.FEATURES) {
    return i18n.t('option.hashtag_features');
  }
  if (type === HASHTAG_TYPE.GENRE_AND_OTHER) {
    return i18n.t('option.hashtag_genre_and_other');
  }
};

export const getKenNameById = (
  listKens: Array<{
    id: number;
    name: string;
    nameKana: string;
    status: number;
    order: number;
    createdAt: string;
  }>,
  kenId: number
) => listKens?.find((ken: any) => ken.id === kenId)?.name;

export const getTreeSelectData = (resourcesHashtag: any) => {
  const getDataForTree = (type: number) =>
    resourcesHashtag
      ?.filter((item: any) => item.type === type)
      .map((item: any) => {
        return { ...item, value: item.id, title: item.hashtag };
      });
  const common = getDataForTree(HASHTAG_TYPE.COMMON);
  const budget = getDataForTree(HASHTAG_TYPE.BUDGET);
  const smoking = getDataForTree(HASHTAG_TYPE.SMOKING);
  const features = getDataForTree(HASHTAG_TYPE.FEATURES);
  const genre = getDataForTree(HASHTAG_TYPE.GENRE_AND_OTHER);

  const treeData: any = [
    { title: i18n.t('option.hashtag_common'), key: '1-1', children: common },
    { title: i18n.t('option.hashtag_budget'), key: '2-1', children: budget },
    { title: i18n.t('option.hashtag_smoking'), key: '3-1', children: smoking },
    { title: i18n.t('option.hashtag_features'), key: '4-1', children: features },
    { title: i18n.t('option.hashtag_genre_and_other'), key: '5-1', children: genre },
  ];
  return treeData;
};

export const getListCitiesByKenId = (listCitiesFull: any, kenId: any) =>
  listCitiesFull?.filter((city: any) => city.kenId === kenId);

export const getStatusPayment = (status: number) => {
  if (status === STATUS_PAYMENT.SUCCESS) {
    return i18n.t('option.payment_success');
  }
  if (status === STATUS_PAYMENT.FAILED) {
    return i18n.t('option.payment_failed');
  }
  if (status === STATUS_PAYMENT.CANCEL) {
    return i18n.t('option.payment_cancel');
  }
};

export const formatBytes = (bytes: any, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};
