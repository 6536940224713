export enum Status {
  Active = 1,
  Inactive = 0,
}

export enum PERMISSIONS {
  RESOURCE_MANAGEMENT = 1,
  LANGUAGE_MANAGEMENT = 2,
  MEMBER_MANAGEMENT = 3,
  RESTAURANT_MANAGEMENT = 4,
  NOTIFICATION_MANAGEMENT = 5,
  USER_MANAGEMENT = 6,
  ROLE_MANAGEMENT = 7,
}

export enum IsSystem {
  Yes = 1,
  No = 0,
}

export enum IsVisible {
  Yes = 1,
  No = 0,
}

export enum COMMON_STATUS {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum LANGUAGE {
  JA = 'ja',
  EN = 'en',
}

export enum GENDER {
  MALE = 1,
  FEMALE = 2,
}

export enum IS_OFFICIAL {
  OFFICIAL = 1,
  UNOFFICIAL = 0,
}

export enum HASHTAG_TYPE {
  COMMON = 1,
  BUDGET = 2,
  SMOKING = 3,
  FEATURES = 4,
  GENRE_AND_OTHER = 5,
}

export enum RESTAURANT_IMAGE_TYPE {
  BANNERS = 1,
  OTHERS = 2,
}

export enum TYPE_GUIDE_AND_QA {
  GUIDE = 1,
  QA = 2,
}

export enum TYPE_NOTIFICATION {
  ALL = 1,
  USER_SELECTED = 2,
}

export enum REDIRECT_TYPE_NOTIFICATION {
  HOME = 1,
  RESTAURANT = 2,
}

export enum IS_RECOMMEND_RESTAURANT {
  YES = 1,
  NO = 0,
}

export enum STATUS_PAYMENT {
  SUCCESS = 1,
  FAILED = 2,
  CANCEL = 3,
}

export enum SERVICE {
  IOS = 1,
  ANDROID = 2,
}

export enum MY_PAGE_TYPE {
  LINK = 1,
  SCREEN = 2,
  POPUP = 3,
}

export enum BANNER_TYPE {
  BANNER_ATTRACTIVE = 1,
  BANNER_SLIDE = 0,
}

export enum SORT_ORDER {
  INCREASE = 1,
  DECREASE = 0,
}
