import React from 'react';

const Languages = React.lazy(() => import('pages/System/Languages'));

//Main Route
const Dashboard = React.lazy(() => import('components/Dashboard'));
const Member = React.lazy(() => import('pages/Member'));
const Restaurant = React.lazy(() => import('pages/Restaurant'));
const RestaurantDetail = React.lazy(() => import('pages/Restaurant/RestaurantDetail'));
const RestaurantAdd = React.lazy(() => import('pages/Restaurant/RestaurantAdd'));
const RestaurantCoupon = React.lazy(() => import('pages/Restaurant/Coupon'));
const Notification = React.lazy(() => import('pages/Notifications'));
const TemplateNotification = React.lazy(() => import('pages/TemplateNotifications'));

const Revenue = React.lazy(() => import('pages/Revenue'));
const Payment = React.lazy(() => import('pages/Payment'));
const CouponDeleted = React.lazy(() => import('pages/CouponDeleted'));

const Roles = React.lazy(() => import('pages/System/Roles'));
// const Configs = React.lazy(() => import('pages/Configs'));
const AccountProfile = React.lazy(() => import('pages/Profile/ChangeProfile'));
const ChangePassword = React.lazy(() => import('pages/Profile/ChangePassword'));
const Staff = React.lazy(() => import('pages/System/Staffs'));
const StaffCreate = React.lazy(() => import('pages/System/Staffs/StaffCreate'));
const StaffEdit = React.lazy(() => import('pages/System/Staffs/StaffEdit'));
const Ken = React.lazy(() => import('pages/Resources/Ken'));
const Hashtag = React.lazy(() => import('pages/Resources/Hashtag'));
const City = React.lazy(() => import('pages/Resources/City'));
const Category = React.lazy(() => import('pages/Resources/Category'));
const RestaurantType = React.lazy(() => import('pages/Resources/RestaurantType'));
const GuideAndQA = React.lazy(() => import('pages/Resources/GuideAndQA'));
const MyPage = React.lazy(() => import('pages/Resources/MyPage'));
const TemplateMail = React.lazy(() => import('pages/Resources/TemplateMail'));
const MemberBenefit = React.lazy(() => import('pages/Resources/MemberBenefit'));
const BannerTop = React.lazy(() => import('pages/Resources/BannerTop'));
const BannerAttractive = React.lazy(() => import('pages/Resources/BannerAttractive'));
const Profession = React.lazy(() => import('pages/Resources/Profession'));
const Age = React.lazy(() => import('pages/Resources/Age'));

const TopPage = React.lazy(() => import('pages/Resources/TopPage'));

//Configs
const ConfigAdmin = React.lazy(() => import('pages/Configs/ConfigAdmin'));
const ConfigSystem = React.lazy(() => import('pages/Configs/ConfigSystem'));
const ConfigRegister = React.lazy(() => import('pages/Configs/ConfigRegister'));

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/member', name: 'Member', component: Member },
  { path: '/restaurant', exact: true, name: 'Restaurant', component: Restaurant },

  { path: '/restaurant/detail/:restaurantId', exact: true, name: 'Restaurant', component: RestaurantDetail },
  { path: '/restaurant/add', exact: true, name: 'Restaurant add', component: RestaurantAdd },
  { path: '/restaurant/coupon/:restaurantId', exact: true, name: 'Restaurant coupon', component: RestaurantCoupon },

  { path: '/notification', name: 'Notification', component: Notification },
  { path: '/template-notifications', name: 'Notification', component: TemplateNotification },

  { path: '/revenue', name: 'Revenue', component: Revenue },
  { path: '/payment', name: 'Payment', component: Payment },
  { path: '/coupon-deleted/:couponId', name: 'Coupon Deleted', component: CouponDeleted },

  //System
  { path: '/roles', name: 'Roles', component: Roles, exact: true },
  { path: '/languages', name: 'Languages', component: Languages },
  { path: '/account/profile', name: 'Account Profile', component: AccountProfile },
  { path: '/account/change-password', name: 'Account Profile', component: ChangePassword },
  { path: '/staffs', exact: true, name: 'Staff', component: Staff },
  { path: '/staffs/create', exact: true, name: 'Staff Create', component: StaffCreate },
  { path: '/staffs/edit/:staffId', exact: true, name: 'Staff Edit', component: StaffEdit },
  //Resource
  { path: '/resources/ken', exact: true, name: 'Ken', component: Ken },
  { path: '/resources/hashtag', exact: true, name: 'Hashtag', component: Hashtag },
  { path: '/resources/city', exact: true, name: 'City', component: City },
  { path: '/resources/category', exact: true, name: 'Category', component: Category },
  { path: '/resources/restaurant-type', exact: true, name: 'Restaurant Type', component: RestaurantType },
  { path: '/resources/guide-and-QA', exact: true, name: 'Guide and QA', component: GuideAndQA },
  { path: '/resources/my-page', exact: true, name: 'My Page', component: MyPage },
  { path: '/resources/template-mail', exact: true, name: 'Template mail', component: TemplateMail },
  { path: '/resources/member-benefit', exact: true, name: 'Member benefit', component: MemberBenefit },
  { path: '/resources/banner-top', exact: true, name: 'Banner Top', component: BannerTop },
  { path: '/resources/banner-attractive', exact: true, name: 'Banner Attractive', component: BannerAttractive },

  { path: '/resources/top-page', exact: true, name: 'Top Page', component: TopPage },
  { path: '/resources/profession', exact: true, name: 'Profession', component: Profession },
  { path: '/resources/age', exact: true, name: 'Age', component: Age },

  //Config
  { path: '/config-admin', exact: true, name: 'Config-admin', component: ConfigAdmin },
  { path: '/config-system', exact: true, name: 'Config system', component: ConfigSystem },
  { path: '/config-register', exact: true, name: 'Config register', component: ConfigRegister },
];

export default routes;
