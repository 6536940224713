import React from 'react';
import { CHeader, CHeaderNav } from '@coreui/react';
import TheHeaderDropdown from './TheHeaderDropdown';
import { useQueryClient } from 'react-query';
import { FaStream } from 'react-icons/fa';
import LanguageDropdown from './LanguageDropdown';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

export default function PageHeader({ toggleSidebar, toggleSidebarMobile }: any) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const profile: any = queryClient.getQueryData('profile');
  const location = useLocation();
  const history = useHistory();

  return (
    <CHeader>
      {!location.pathname.includes('multiple') ? (
        <>
          <FaStream className="toggleIcon ml-15 d-lg-none pointer" onClick={toggleSidebarMobile} />
          <FaStream className="toggleIcon ml-15 d-md-down-none pointer" onClick={toggleSidebar} />
        </>
      ) : (
        <div className="d-flex align-items-center pl-15">
          {/* <img src={logoWhite} alt="" className="img-fluid pointer" onClick={() => history.push('/dashboard')} /> */}
        </div>
      )}

      <CHeaderNav className="ml-auto" style={{ marginRight: '32px' }}>
        {process.env.REACT_APP_ENV !== 'dev' ? null : <LanguageDropdown />}
        &nbsp;
        <div className="greeting">{profile?.data.fullName}</div>
        <TheHeaderDropdown profile={{ avatar: profile?.data.avatar }} />
      </CHeaderNav>
    </CHeader>
  );
}
